<template>
	<section>
		<div class="row">
			<div class="col-lg-4 col-12 mb-1">
				<select class="form-control mx-auto" @change="onChange" v-if="inputValue.length > 0" ref="selector">
					<option v-for="(obj, index) in inputValue" :key="index" :value="obj.name" :selected="index === 0">
						{{ obj.name }}
					</option>
				</select>
			</div>

			<div class="col-auto">
				<b-button @click="editGroup" :disabled="disabled" v-if="value.length > 0"> {{ $t('supplier.groupTemplate.edit') }}</b-button>
			</div>

			<div class="col-auto">
				<b-button @click="addGroup" :disabled="disabled"> {{ $t('supplier.groupTemplate.add') }}</b-button>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'SupplierGroupTemplate',
	props: {
		value: Array,
		disabled: Boolean,
	},
	mounted() {},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
	methods: {
		async addGroup() {
			const { value: groupName } = await this.$swal.fire({
				title: 'Indsæt rammeaftale navn',
				input: 'text',
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			if (groupName) {
				this.$emit('templateAdded', groupName)
			}
		},
		onChange(event) {
			this.$emit('indexChanged', event.target.selectedIndex)
		},
		async editGroup() {
			var currentGroupName = this.$refs.selector.value
			const { value: newGroupName } = await this.$swal.fire({
				title: 'Ændr rammeaftale navn',
				input: 'text',
				inputValue: currentGroupName,
				inputValidator: (value) => {
					if (!value) {
						return 'You need to write something!'
					}
				},
			})
			if (currentGroupName) {
				var currentGroup = this.inputValue.find((item) => {
					return item.name == currentGroupName
				})
				currentGroup.name = newGroupName
			}
		},
	},
}
</script>
<style lang="scss"></style>
